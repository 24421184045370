interface DrupalPagerData {
  current_page: number
  total_items: number
  total_pages: number
  items_per_page: number
}

export interface PagerData {
  totalItems: number
}

export function formatPagerData (data: DrupalPagerData): PagerData {
  return {
    totalItems: data.total_items
  };
}
