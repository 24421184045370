import { useRouter } from 'next/router';
import React, { createContext, useState, useEffect, useContext, FunctionComponent, FC, ReactElement } from 'react';

interface HValidation {
  history: string[]
  prev: string
  back(): void
  findMostRecent(predicate: (s: string) => boolean): string | undefined
}

const HistoryContext = createContext<HValidation>({} as HValidation);

export const HistoryProvider = ({ children }: { children: React.ReactNode}) => {
  const { asPath, push, pathname } = useRouter();
  const [ history, setHistory ] = useState<string[]>([]);
  const [ prev, setPrev ] = useState<string>('');

  const back = () => {
    for (let i = history.length - 2; i >= 0; i--) {
      const route = history[ i ];
      if (!route.includes('#') && route !== pathname) {
        push(route);

        // if you want to pop history on back
        const newHistory = history.slice(0, i);
        setHistory(newHistory);

        if(i < 0) {
          setPrev(history[ i - 1 ]);
        }

        break;
      }
    }
  };

  const findMostRecent = (predicate: (s: string) => boolean): string | undefined => {
    for(let i = history.length - 1; i >= 0; i--) {
      if(predicate(history[ i ])) {
        return history[ i ];
      }
    }
  };

  useEffect(() => {
    
    if(history.length > 0) {
      setPrev(history[ history.length - 1 ]);
    }

    setHistory(previous => [ ...previous, asPath ].slice(-10));
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asPath ]);

  return (
    <HistoryContext.Provider
      value={{
        back,
        findMostRecent,
        prev,
        history,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export function useHistory (): HValidation {
  const context = useContext(HistoryContext);
  return context;
}