export const formatMoneyNumber = (num: number): string => {
  if(typeof num !== 'number') {
    return '';
  }

  return `$${num.toFixed(2)}`;  
};

export const formatMoneyString = (str: string): string => {
  if(typeof str !== 'string' || str.length === 0) {
    return '';
  }

  const [ dollars, cents ] = str.split('.');

  if(!cents) {
    str = `${dollars}.00`;
  } else if(cents.length === 1) {
    str = `${dollars}.${cents}0`;
  }

  if(str[ 0 ] !== '$') {
    str = `$${str}`;
  }

  return str;
};

// Normalizes string and number values representing prices in dollars. Appends
// a dollar sign to the front of the returned string.
export const formatMoney = (raw: string | number): string => {
  if(typeof raw === 'string') {
    return formatMoneyString(raw);
  }

  if(typeof raw === 'number') {
    return formatMoneyNumber(raw);
  }

  return '';
};

export default formatMoney;