/* eslint-disable no-console */
import { RefObject, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { EventListener } from '@/lib/util/eventListener';

export type AdUnit = 
  'leaderboard_atf' |
  'leaderboard_btf' |
  'med_rect_atf' | 
  'med_rect_btf' |
  'corner_ad_video' | 
  'precontent_ad_video' | 
  'standard_iab';

declare global {
  interface Window {
    Bolt?: {
      on: (unit: string, eventName: string, callback: () => void) => void
      BOLT_AD_COMPLETE: string
      BOLT_AD_ERROR: string
      BOLT_AD_REQUEST_START: string
    }
    __boltReady?: boolean
    __hasTriggeredAtLeastOneNewPage?: boolean
    ramp?: {
      que: any[]
      passiveMode: boolean
      settings: {
        slots: {
          [key: string]: {
            id: string
          }
        }
      }
      spaNewPage: (path?: string) => void
      spaAddAds: (units: { type: AdUnit, selectorId?: string }[]) => void
      spaAds: (options?: {
        ads: { type: AdUnit, selectorId?: string }[], 
        countPageview?: boolean, 
        path?: string
      }) => void
      addUnits: (units: { type: AdUnit, selectorId?: string }[]) => Promise<void>
      destroyUnits: (units: AdUnit[] | string) => Promise<void>
      hideSlots: (slotIds: string[]) => void
      onPlayerReady: () => void
      displayUnits: () => void
      showAdSlot: (slotId: string) => void
      hideAdSlot: (slotId: string) => void
    }
  }
}

export const playwireEvents = new EventListener<{
  newPage: () => void
  precontentRequestStart: () => void
  precontentComplete: () => void
}>();

export function hidePlaywireAdSlots () {
  if (window.ramp?.settings?.slots) {
    const slotsToHide = Object
      .keys(window.ramp.settings.slots)
      .map((slotKey) => window.ramp.settings.slots[ slotKey ].id);

    window.ramp.hideSlots(slotsToHide);
  }
  if (window.ramp?.destroyUnits) {
    window.ramp.destroyUnits('all');
  }
}

export function usePlaywireNewPageEvent (units: { type: AdUnit, selectorId?: string }[] = []) {
  useEffect(() => {
    const handler = () => {
      console.log('playwire new page event', units);
      newPage(units);
    };
    playwireEvents.on('newPage', handler);

    () => {
      playwireEvents.off('newPage', handler);
    };
  }, [ units ]);
}

export function useHidePlaywireSlotsOnResize (ref: RefObject<HTMLElement>) {
  useEffect(() => {
    const el = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const adEls = entry.target.querySelectorAll('.pw-tag');
          const adIds = Array.from(adEls).map((el) => el.id);
          const parentVisible = !!(entry.target as HTMLDivElement)?.offsetParent;

          if (parentVisible) {
            adIds.forEach((id) => window.ramp.showAdSlot(id));
          } else {
            adIds.forEach((id) => window.ramp.hideAdSlot(id));
          }
        }
      }, 
      {
        threshold: 1.0,
      }
    );
    observer.observe(el);

    return () => {
      observer.unobserve(el);
      observer.disconnect();
    };
  }, [ ref ]);
}

export function usePlaywirePrecontent () {
  return async function showPlaywirePrecontent (callback: () => void) {
    const onComplete = () => {
      playwireEvents.offAll('precontentRequestStart');
      playwireEvents.offAll('precontentComplete');
      callback();

      console.log('===== PLAYWIRE: tryShowPrecontent sequence complete =====');
    };
    const tryShowPrecontent = async (numTries = 0) => {
      if (numTries > 10) {
        onComplete();
        return;
      }
      if (!window.__boltReady) {
        setTimeout(() => tryShowPrecontent(numTries + 1), 250);
        return;
      }

      try {
        console.log('===== PLAYWIRE: tryShowPrecontent sequence start =====');

        await window.ramp.addUnits([ { type: 'precontent_ad_video' } ]);
        window.ramp.displayUnits();
        console.log('-> PLAYWIRE: Attempting to display Playwire pre-content video...');
            
        // If the video player fails to initialize within 5 seconds, assume it
        // won't load and resolve the preroll.
        const playerTimeout = setTimeout(onComplete, 5000);

        playwireEvents.offAll('precontentRequestStart');
        playwireEvents.on('precontentRequestStart', () => { 
          console.log('-> PLAYWIRE: usePlaywirePrecontent - onRequestStart');
          clearTimeout(playerTimeout);
        });

        playwireEvents.offAll('precontentComplete');
        playwireEvents.on('precontentComplete', () => {
          console.log('-> PLAYWIRE: usePlaywirePrecontent - onComplete');
          onComplete();
        });
      } catch (err) {
        console.log('-> PLAYWIRE: Error displaying Playwire pre-content video:', err);
        onComplete();
      }
    };
    tryShowPrecontent();
  };
}

let adUnits = [];

export async function newPage (units: { type: AdUnit, selectorId?: string }[] = []) {
  // Filter unitsToAdd so that we don't add duplicate units
  // adUnits contains the units that are already added
  // Units that are eligiable to add should not match 'type', or if 'selectorId' is present, 
  // should not match 'selectorId' and 'type'
  let unitsToAdd = units.filter((unit) => {
    return !adUnits.some((addedUnit) => {
      if (unit.selectorId) {
        return addedUnit.selectorId === unit.selectorId && addedUnit.type === unit.type;
      }
      return addedUnit.type === unit.type;
    });
  });

  unitsToAdd = unitsToAdd.filter((unit) => {
    if (!unit.selectorId) return true;
    const el = document.getElementById(unit.selectorId);
    return !!el;
  });

  console.log('-> PLAYWIRE: newPage', unitsToAdd);
  adUnits.push(...unitsToAdd);
}

export async function displayUnits (units: { type: AdUnit, selectorId?: string }[] = []) {
  while (
    !window.__hasTriggeredAtLeastOneNewPage || 
    !window.ramp?.addUnits || 
    !window.ramp?.destroyUnits
  ) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  units = units.filter((unit) => {
    if (!unit.selectorId) return true;
    const el = document.getElementById(unit.selectorId);
    return !!el;
  });
  
  console.log(':: PLAYWIRE: displayUnits called');  
  console.log('-> PLAYWIRE: destroyUnits', units);
  await window.ramp.destroyUnits('all');
  console.log('-> PLAYWIRE: addUnits', units);
  await window.ramp.addUnits(units);
  console.log('-> PLAYWIRE: displayUnits');
  window.ramp.displayUnits();
  console.log(':: PLAYWIRE: displayUnits complete');  
}

interface RampProps {
  PUB_ID: string
  WEBSITE_ID: string
}

export function Playwire ({ PUB_ID, WEBSITE_ID }: RampProps) {
  const [ rampComponentLoaded, setRampComponentLoaded ] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const authState = localStorage.getItem('.storage.auth') 
      ? JSON.parse(localStorage.getItem('.storage.auth') as string) 
      : {};

    if (!PUB_ID || !WEBSITE_ID) {
      console.log('Missing Publisher Id and Website Id');
      return;
    }

    const tryNewPage = async () => {
      if (authState.user?.isPremiumUser && window.ramp.destroyUnits) {
        try {
          hidePlaywireAdSlots();
        } catch {}
        return;
      }

      if (window.ramp.addUnits && window.ramp.destroyUnits) {
        console.log(':: PLAYWIRE: destroyUnits');
        await window.ramp.destroyUnits('all');
        adUnits = [];
        playwireEvents.trigger('newPage');
        if (window.__hasTriggeredAtLeastOneNewPage) {
          playwireEvents.offAll('newPage');
        }
        console.log(':: PLAYWIRE: addUnits', adUnits);
        await window.ramp.addUnits(adUnits);
        console.log(':: PLAYWIRE: displayUnits');
        await window.ramp.displayUnits();
        window.__hasTriggeredAtLeastOneNewPage = true;
      } else {
        setTimeout(tryNewPage, 250);
      }
    };

    if (!rampComponentLoaded) {
      setRampComponentLoaded(true);

      if (authState.user?.isPremiumUser) {
        try {
          hidePlaywireAdSlots();
        } catch {}
        return;
      }
      
      window.ramp = window.ramp || {} as Window[ 'ramp' ];
      window.ramp.que = window.ramp.que || [];
      window.ramp.passiveMode = true;

      // Load the Ramp configuration script
      const configScript = document.createElement('script');
      configScript.src = `https://cdn.intergient.com/${PUB_ID}/${WEBSITE_ID}/ramp.js`;
      document.body.appendChild(configScript); // Insert before closing </body> tag

      configScript.onload = () => {
        if (authState.ready && authState.user?.isPremiumUser) {
          try {
            hidePlaywireAdSlots();
          } catch {}
          return;
        }

        tryNewPage();

        window.ramp.onPlayerReady = () => {
          if (window.__boltReady) return;
          window.__boltReady = true;
          console.log(':: PLAYWIRE: Bolt player ready!');

          window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_REQUEST_START, () => {
            console.log(':: PLAYWIRE: BOLT_AD_REQUEST_START.');
            playwireEvents.trigger('precontentRequestStart');
          });
          window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_COMPLETE, () => {
            console.log(':: PLAYWIRE: BOLT_AD_COMPLETE.');
            playwireEvents.trigger('precontentComplete');
          });
          window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_ERROR, () => {
            console.log(':: PLAYWIRE: BOLT_AD_ERROR.');
            playwireEvents.trigger('precontentComplete');
          });
        };
      };
    }

    // cleanUp function to remove units on component unmount
    if (window.ramp?.que) {
      window.ramp.que.push(() => {
        tryNewPage();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.asPath ]);

  return (<></>);
};
