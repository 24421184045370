import { Block, createComponent, If, Loader } from 'react-commons';
import { useEffect, useState } from 'react';

import { EventListener } from '@/lib/util/eventListener';

import style from './index.module.scss';

export const loadingPanelEvents = new EventListener<{
  show: () => void
}>();

export default createComponent('BackgroundImage', { style }, function BackgroundImage ({ className }, props) {
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    const handleSetVisible = () => {
      setVisible(true);
    };
    loadingPanelEvents.on('show', handleSetVisible);

    return () => {
      loadingPanelEvents.off('show', handleSetVisible);
    };
  }, []);

  return (
    <>
      {
        If(visible, () => (
          <div className='LoadingPanel'>
            <Loader large />
          </div>
        )).EndIf()
      }
    </>
  );
});
