import { logErrorEvent } from '@/lib/util/logErrorEvent';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const instance = axios.create();

// Defaults
instance.defaults.baseURL = process.env.NEXT_PUBLIC_DRUPAL_API_URL;
instance.defaults.withCredentials = true;
instance.defaults.timeout = 30000; // wait for 30s on a timeout

axiosRetry(instance, { 
  retries: 10,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (err) => {
    logErrorEvent('AxiosRetry', false, err);
  }
});

export default instance;

export const jsonApiHeaders = {
  'Accept': 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json'
};
