import axios from '@/lib/drupal/api/axiosInstance';
import GamesModel, { DrupalGameData, ExtendedGameData, GameData, GameTag } from '@/lib/drupal/models/Games';
import { ThemeState } from '@/lib/stores/theme';
import SwagModel from '@/lib/swag/models/Swag';
import { decodeHtmlEntities } from '@/lib/util/decodeHtmlEntities';
import formatMoney from '@/lib/util/formatMoney';
import { isTruthy } from '@/lib/util/isTruthy';
import { pagingQueryString } from '@/lib/util/pagingQueryString';
import { getZonedDate } from '@/lib/util/zonedTime';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';


export interface DrupalPublicUserData {
  name: string
  uid: string
  user_picture_export: string
  field_member_id: string
  field_if_i_could_invent_an_onlin_export: string | null
  field_if_i_were_a_game_character_export: string | null
  field_my_favorite_game_of_all_ti_export: string | null
  field_my_favorite_music_when_pla_export: string | null
  field_my_favorite_shockwave_game_export: string | null
  field_my_favorite_shockwave_genr_export: string | null
  field_the_one_word_that_best_des_export: string | null
  field_when_i_m_playing_games_i_l: string | null
  field_when_you_re_not_playing_ga: string | null
  field_your_hometown_export: string | null
}

export interface DrupalUserData {
  name: string
  uid: string
  created: string
  changed: string
  user_picture_export: string
  mail_export: string
  roles_target_id: string
  field_member_cck_export: string
  field_member_id_export: string
  field_endpoint_created_export: string
  field_first_name_export: string | null
  field_last_name: string | null
  field_gender_export: string | null
  field_uname_type_1_export: string
  field_uname_type_2_export: string | null
  field_if_i_could_invent_an_onlin_export: string | null
  field_if_i_were_a_game_character_export: string | null
  field_my_favorite_game_of_all_ti_export: string | null
  field_my_favorite_music_when_pla_export: string | null
  field_my_favorite_shockwave_game_export: string | null
  field_my_favorite_shockwave_genr_export: string | null
  field_the_one_word_that_best_des_export: string | null
  field_when_i_m_playing_games_i_l: string | null
  field_when_you_re_not_playing_ga: string | null
  field_your_hometown_export: string | null
  field_profile_headline_export: string | null
  field_studio_address_export: string | null
  field_theme: 'modern' | 'classic'
  field_opt_in: string | null
}

export interface DrupalFriendData {
  name: string
  view_user: string
  uid: string
  user_picture: string
  flagged_on: string
}

interface DrupalAvatarCategoryData {
  rows: {
    name: string
    tid: string
  }[]
}

export interface AvatarCategoryData {
  name: string
  id: string
}

interface DrupalAvatarData {
  fid: string
  filename: string
  uri: string
  name: string
}

export interface AvatarData {
  fid: string
  url: string
}

interface DrupalAvatarHistoryData {
  currentAvatar: {
    avatarImage: string
    fid: string
  },
  avatarHistory: {
    avatarImage: string
    fid: string
  }[]
}

export interface AvatarHistoryData {
  currentAvatar: AvatarData
  avatarHistory: AvatarData[]
}

export interface PublicUserData {
  uid: string
  displayName: string
  avatarUrl: string
  profileHref: string
  memberId: number
  personalInfo: {
    hometown: string
    inventedGame: string
    gameCharacter: string
    favoriteGame: string
    favoriteShockwaveGame: string
    favoriteShockwaveGenre: string
    describesMe: string
    snackOn: string
    notPlayingGames: string
  }
}

export interface UserData extends PublicUserData {
  email: string | null
  tokenBalance: number
  displayName: string
  isPremiumUser: boolean
  isAdminUser: boolean
  createdAt: number
  memberIdHash: string
  layoutPreference: 'classic' | 'modern'
  emailOptIn?: boolean
}

type FriendFields = 
  'uid' | 
  'displayName' | 
  'avatarUrl' | 
  'profileHref';

export interface SubscriptionStatus {
  status?:
    'unlimited_active' | 
    'unlimited_inactive' | 
    'unlimited_expiring'
  isActive: boolean
  validUntil?: string
  cancelTime?: string
  subscriptionEndTime?: string
  nextBillingDate?: string
}

export interface SubscriptionData {
  subscriptionId: string
  paymentUuid: string
  cost: string
  renewalFrequency: 'annual' | 'monthly'
}

export interface FriendData extends Pick<UserData, FriendFields> {
  friendSince: number
}

export default class UsersModel {

  static transformPublic (rawData: DrupalPublicUserData): PublicUserData {
    const avatarUri = rawData.user_picture_export && rawData.user_picture_export !== 'null'
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + rawData.user_picture_export
      : '/images/userAvatarPlaceholder.png';

    return {
      uid: rawData.uid,
      memberId: parseInt(rawData.field_member_id),
      displayName: rawData.name,
      avatarUrl: avatarUri,
      profileHref: `/profile/${rawData.uid}`,
      personalInfo: {
        hometown: rawData.field_your_hometown_export || '',
        inventedGame: rawData.field_if_i_could_invent_an_onlin_export || '',
        gameCharacter: rawData.field_if_i_were_a_game_character_export || '',
        favoriteGame: rawData.field_my_favorite_game_of_all_ti_export || '',
        favoriteShockwaveGame: rawData.field_my_favorite_shockwave_game_export || '',
        favoriteShockwaveGenre: rawData.field_my_favorite_shockwave_genr_export || '',
        describesMe: rawData.field_the_one_word_that_best_des_export || '',
        snackOn: rawData.field_when_i_m_playing_games_i_l || '',
        notPlayingGames: rawData.field_when_you_re_not_playing_ga || '',
      },
    };
  };

  static transform (rawData: DrupalUserData, tokenBalance: number, hideEmail?: boolean): UserData {
    const roles = rawData.roles_target_id
      .split(',')
      .map((role) => role.trim());

    const avatarUri = rawData.user_picture_export && rawData.user_picture_export !== 'null'
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + rawData.user_picture_export
      : '/images/userAvatarPlaceholder.png';

    return {
      uid: rawData.uid,
      email: hideEmail ? null : (rawData.mail_export || ''),
      tokenBalance,
      displayName: rawData.name,
      avatarUrl: avatarUri,
      isPremiumUser: roles.includes('Shockwave Unlimited'),
      isAdminUser: roles.includes('administrator'),
      createdAt: getZonedDate(rawData.created).getTime(),
      profileHref: `/profile/${rawData.uid}`,
      memberId: parseInt(rawData.field_member_id_export),
      memberIdHash: rawData.field_member_cck_export || '',
      layoutPreference: rawData.field_theme,
      emailOptIn: isTruthy(rawData.field_opt_in),
      personalInfo: {
        hometown: rawData.field_your_hometown_export || '',
        inventedGame: rawData.field_if_i_could_invent_an_onlin_export || '',
        gameCharacter: rawData.field_if_i_were_a_game_character_export || '',
        favoriteGame: rawData.field_my_favorite_game_of_all_ti_export || '',
        favoriteShockwaveGame: rawData.field_my_favorite_shockwave_game_export || '',
        favoriteShockwaveGenre: rawData.field_my_favorite_shockwave_genr_export || '',
        describesMe: rawData.field_the_one_word_that_best_des_export || '',
        snackOn: rawData.field_when_i_m_playing_games_i_l || '',
        notPlayingGames: rawData.field_when_you_re_not_playing_ga || '',
      },
    };
  }

  static transformFriend (rawData: DrupalFriendData): FriendData {
    return {
      uid: rawData.uid,
      displayName: rawData.name,
      avatarUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + rawData.user_picture,
      profileHref: rawData.view_user,
      friendSince: getZonedDate(rawData.flagged_on).getTime(),
    };
  }

  static async getById (userId: string, withTokenBalance?: boolean): Promise<UserData | null> {
    let response: AxiosResponse;
    try {
      response = await axios.get(`/api/user/entity/id/${userId}`);
    } catch (error) {
      logErrorEvent('Get User by ID (Get User)', false, error);
      throw error;
    }

    let tokenBalance = -1;

    if (withTokenBalance) {
      try {
        tokenBalance = await SwagModel.getTokenBalance();
      } catch (error) {
        logErrorEvent('Get User by ID (Get Token Balance)', false, error);
      }
    }

    return response.data[ 0 ] === undefined 
      ? null
      : UsersModel.transform(response.data[ 0 ], tokenBalance);
  }

  static async getPublicByUsername (name: string): Promise<PublicUserData | null> {
    let response: AxiosResponse;
    try {
      response = await axios.get(`/api/user/profile/public/name/${name}`);
    } catch (error) {
      logErrorEvent('Get User by ID (Get User)', false, error);
      throw error;
    }

    return response.data[ 0 ] === undefined
      ? null
      : UsersModel.transformPublic(response.data[ 0 ]);
  }

  static async getFavoriteGamesForUser (userId: string, limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/user/profile/block/favorite-games/${userId}?` +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Favorite Games for User', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getFavoriteGamesForUserPaged (userId: string, limit: number, offset: number): Promise<
  { 
    games: GameData[],
    numPages: number
  }> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/user/profile/block/favorite-games/${userId}?` +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Favorite Games for User', false, error);
      throw error;
    }
    
    if (!response.data || !response.data.rows) {
      return {
        games: [],
        numPages: 0
      };
    }

    return {
      games: response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game)),
      numPages: Number(response.data.pager.total_pages),
    };
  }

  static async getDownloadGamesForUser (userId: string, limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/sw/api/user/profile/block/downloads/${userId}?` +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Downloads for User', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
    
  }

  static async getFriendsForUser (userId: string, limit: number, offset: number): Promise<FriendData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/user/profile/block/friends/${userId}?` +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Friends for User', false, error);
      throw error;
    }

    return response.data.rows.map((friend: DrupalFriendData) => UsersModel.transformFriend(friend));
  }

  static async getRecommendedGamesForUser (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/user/profile/sidebar/block/games-for-you?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Recommended Games for User', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async updateEmail (userId: string, email: string, password: string) {
    const payload = {
      pass: [
        { existing: password },
      ],
      mail: [
        { value: email },
      ],
    };

    let response: AxiosResponse;
    try {
      response = await axios.patch(`/user/${userId}`, payload);
    } catch (error) {
      logErrorEvent('Update Email', false, error);
      throw error;
    }

    return response.data;
  }

  static async updatePassword (userId: string, existingPassword: string, newPassword: string) {
    const payload = {
      pass: [
        { 
          existing: existingPassword,
          value: newPassword,
        },
      ]
    };
    
    let response: AxiosResponse;
    try {
      response = await axios.patch(`/user/${userId}`, payload);
    } catch (error) {
      logErrorEvent('Update Password', false, error);
      throw error;
    }

    return response.data;
  }

  static async updateEmailAndPassword (userId: string, email: string, password: string, newPassword: string) {
    const payload = {
      pass: [
        { 
          existing: password,
          value: newPassword,
        }
      ],
      mail: [
        { value: email },
      ]
    };

    let response: AxiosResponse;
    try {
      response = await axios.patch(`/user/${userId}`, payload);
    } catch (error) {
      logErrorEvent('Update Email and Password', false, error);
      throw error;
    }

    return response.data;
  }

  static async updatePersonalInfo (userId: string, personalInfo: UserData[ 'personalInfo' ]) {
    const payload = {
      field_your_hometown: [
        {
          value: personalInfo.hometown || '',
        }
      ],
      field_if_i_could_invent_an_onlin: [
        {
          value: personalInfo.inventedGame || '',
        }
      ],
      field_if_i_were_a_game_character: [
        {
          value: personalInfo.gameCharacter || '',
        }
      ],
      field_my_favorite_game_of_all_ti: [
        {
          value: personalInfo.favoriteGame || '',
        }
      ],
      field_my_favorite_shockwave_game: [
        {
          value: personalInfo.favoriteShockwaveGame || '',
        }
      ],
      field_my_favorite_shockwave_genr: [
        {
          value: personalInfo.favoriteShockwaveGenre || '',
        }
      ],
      field_the_one_word_that_best_des: [
        {
          value: personalInfo.describesMe || '',
        }
      ],
      field_when_i_m_playing_games_i_l: [
        {
          value: personalInfo.snackOn || '',
        }
      ],
      field_when_you_re_not_playing_ga: [
        {
          value: personalInfo.notPlayingGames || '',
        }
      ],
    };

    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'patch',
        url: `/user/${userId}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      logErrorEvent('Update Personal Info for User', false, error);
      throw error;
    }

    return response.data;
  }

  static async updateLayoutPreference (userId: string, layout: ThemeState[ 'layout' ]) {
    const payload = {
      field_theme: [
        {
          value: layout,
        }
      ],
    };

    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'patch',
        url: `/user/${userId}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      logErrorEvent('Update Layout Preference for User', false, error);
      throw error;
    }

    return response.data;
  }

  static async toggleFavoriteGame (gameId: string, isFavorite: boolean) {
    const url = isFavorite
      ? '/sw/api/flag/node'
      : '/sw/api/unflag/node';

    let response: AxiosResponse;
    try {
      response = await axios.post(url, {
        nid: gameId,
        flag_id: 'favorite_game',
      });
    } catch (error) {
      logErrorEvent('Toggle Favorite', false, error);
      throw error;
    }

    return response.data;
  }

  static async isFavoriteGame (gameId: string): Promise<boolean> {
    let response: AxiosResponse;
    try {
      response = await axios.post('/sw/api/flag/status/node', {
        nid: gameId,
        flag_id: 'favorite_game',
      });
    } catch (error) {
      logErrorEvent('Check Is Favorite', false, error);
      throw error;
    }

    return !!response.data.flagged_status;
  }

  static async rateGame (gameId: string, rating: number) {
    const payload = {
      nid: gameId,
      rating_value: rating,
    };
    
    let response: AxiosResponse;
    try {
      response = await axios.post('/sw/api/post/rate/game', payload);
    } catch (error) {
      logErrorEvent('Rate Game', false, error);
      throw error;
    }

    return response.data;
  }

  static async hasRatedGame (gameId: string, userId: string) {
    const payload = {
      nid: gameId, 
      user_id: userId,
    };

    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: '/sw/api/game/rate/status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      logErrorEvent('Rate Game', false, error);
      throw error;
    }

    return !!parseInt(response.data.vote_status);
  }

  static async tagGame (gameId: string, tags: GameTag[]) {
    try {
      await Promise.all(tags.map((tag) => {
        const payload = {
          nid: gameId,
          flag_id: tag,
        };
        
        return axios.post('/sw/api/flag/node', payload);
      }));
    } catch (error) {
      logErrorEvent('Tag Game', false, error);
      throw error;
    }

    return true;
  }

  static async rateComment (commentId: string, isHelpful: boolean) {
    let response: AxiosResponse;
    try {
      response = await axios.post('/sw/api/flag/comment', {
        cid: commentId, 
        flag_id: isHelpful 
          ? 'comment_helpful' 
          : 'comment_unhelpful',
      });
    } catch (error) {
      logErrorEvent('Rate Comment', false, error);
      throw error;
    }

    return response.data;
  }

  static async getSubscriptionStatus (): Promise<SubscriptionStatus> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/sw/api/get/subscription/status');
    } catch (error) {
      logErrorEvent('Get Subscription Status', false, error);
      throw error;
    }

    const status = (
      response.data.status === 'regular' || 
      response.data.status === 'no_subscription'
    )
      ? null
      : response.data.status;

    const isActive = (
      response.data.status === 'unlimited_active' ||
      response.data.status === 'unlimited_expiring' 
    );

    return {
      status,
      isActive,
      validUntil: response.data.end_time_status,
      cancelTime: response.data.cancel_time || null,
      subscriptionEndTime: response.data.subscription_end_time || null,
      nextBillingDate: response.data.next_bill_date || null
    };
  }

  static async getSubscriptionData (): Promise<SubscriptionData | null> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/sw/api/get/subscription/data');
    } catch (error) {
      logErrorEvent('Get Subscription Data', false, error);
      throw error;
    }

    if (response.data === 'no_subscription') {
      return null;
    }

    return {
      subscriptionId: response.data.subscription_id,
      paymentUuid: response.data.payment_uuid,
      cost: formatMoney(response.data.rebill_amount),
      renewalFrequency: response.data.rebill_frequency === '1' ? 'monthly' : 'annual'
    };
  }

  static async cancelSubscription (userId: string) {
    let response: AxiosResponse;
    try {
      response = await axios.post('/sw/api/cancel/subscription?_format=json', {
        uid: userId
      });
    } catch (error) {
      logErrorEvent('Cancel Subscription', false, error);
      throw error;
    }

    return response.data;
  }

  static async getAvatarCategories (): Promise<AvatarCategoryData[]> {
    // https://dev.shockwave.com/sw/api/avatar-categories

    let response: AxiosResponse;
    try {
      response = await axios.get('/sw/api/avatar-categories');
    } catch (error) {
      logErrorEvent('Get Avatar Categories', false, error);
      throw error;
    }

    return response.data.rows.map((category: DrupalAvatarCategoryData[ 'rows' ][ 0 ]) => ({
      name: category.name,
      id: category.tid,
    }));
  }

  static async getAvatarHistory (userId: string): Promise<AvatarHistoryData> {
    // https://dev.shockwave.com/sw/api/user/profile/get-avatar-usage/5851011

    let response: AxiosResponse;
    try {
      response = await axios.get(`/sw/api/user/profile/get-avatar-usage/${userId}`);
    } catch (error) {
      logErrorEvent('Get Avatar History', false, error);
      throw error;
    }

    return {
      currentAvatar: response.data.currentAvatar ? 
        {
          fid: response.data.currentAvatar.fid,
          url: response.data.currentAvatar.avatarImage
            ? (process.env.NEXT_PUBLIC_IMAGE_DOMAIN + response.data.currentAvatar.avatarImage)
            : '/images/userAvatarPlaceholder.png',
        }
        : null,
      avatarHistory: response.data.avatarHistory.map((avatar: DrupalAvatarHistoryData[ 'avatarHistory' ][ 0 ]) => ({
        fid: avatar.fid,
        url: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + avatar.avatarImage,
      })),
    };
  }

  static async getAvatarsByCategory (id: string): Promise<AvatarData[]> {
    // https://dev.shockwave.com/sw/api/user-avatars?avatar_category=2004&items_per_page=All

    let response: AxiosResponse;
    try {
      response = await axios.get(`/sw/api/user-avatars?avatar_category=${id}&items_per_page=All`);
    } catch (error) {
      logErrorEvent('Get Avatars by Category', false, error);
      throw error;
    }

    const avatars = response.data.rows[ Object.keys(response.data.rows)[ 0 ] ];

    return avatars.map((avatar: DrupalAvatarData) => ({
      fid: avatar.fid,
      url: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + avatar.uri,
    }));
  }

  static async setAvatar (userId: string, avatarId: string) {
    // https://dev.shockwave.com/user/5851011?_format=json

    const payload = {
      user_picture: [
        {
          target_id: avatarId,
        }
      ]
    };

    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'patch',
        url: `/user/${userId}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      logErrorEvent('Set Avatar', false, error);
      throw error;
    }
  }

  static async checkIsFriend (friendsId: string) {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: '/sw/api/flag/status/friend',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify({
          friend_uid: friendsId
        })
      });
    } catch (error) {
      logErrorEvent('Check Is Friend', false, error);
      throw error;
    }

    return response.data?.flagged_status === 1;
  }

  static async addRemoveFriend (friendsId: string, addFriend: boolean) {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: `/sw/api/${addFriend ? 'flag' : 'unflag'}/friend`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify({
          friend_uid: friendsId
        })
      });
    } catch (error) {
      logErrorEvent('Add/Remove Friend', false, error);
      throw error;
    }

    return response.data;
  }

  static async updateNotificationPreferences (userId: string, preferences: Record<string, boolean>) {

    const payload = {
      field_opt_in: [
        {
          value: preferences.emailMarketingOptIn,
        }
      ],
    };

    let response: AxiosResponse;

    try {
      response = await axios.request({
        method: 'patch',
        url: `/user/${userId}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        params: { 
          _format: 'json'
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      logErrorEvent('Update Notification Preference for User', false, error);
      throw error;
    }

    return response.data;
  }

}

export function mapPersonalInfo (personalInfo: UserData[ 'personalInfo' ], forceEmpty?: boolean): [ string, string ][] {
  const mappedQuestions: [ string, string ][] = [];

  Object.keys(personalInfo).forEach((key) => {
    const value = decodeHtmlEntities(personalInfo[ key as keyof UserData[ 'personalInfo' ] ] || '');
    
    let label = '';
   
    switch (key) {
    case 'hometown': {
      label = 'Hometown';
      break;
    }
    case 'inventedGame': {
      label = 'If I invented an online game, it would be';
      break;
    }
    case 'gameCharacter': {
      label = 'If I could be a game character, I would be';
      break;
    }
    case 'favoriteGame': {
      label = 'My favorite game of all time is';
      break;
    }
    case 'favoriteShockwaveGame': {
      label = 'My favorite shockwave game is';
      break;
    }
    case 'favoriteShockwaveGenre': {
      label = 'My favorite shockwave genre is';
      break;
    }
    case 'describesMe': {
      label = 'The one word that best describes me is';
      break;
    }
    case 'snackOn': {
      label = 'When I\'m playing games, I like to snack on';
      break;
    }
    case 'notPlayingGames': {
      label = 'When I\'m not playing games, I would be';
      break;
    }
    }

    if (label && (forceEmpty || value)) {
      mappedQuestions.push([ label, value ]);
    }
  });

  return mappedQuestions;
}
