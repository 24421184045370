import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import ReactGA4 from 'react-ga4';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function captureEvent (event: string, data: any) {
  // Extra data
  data = {
    ...data,
    layout: Cookies.get('sw_result_layout'),
  };

  // Google Analytics
  ReactGA4.event(event, data);

  // PostHog
  posthog.capture(event, data);
}
