import { getIdentifier } from 'react-commons';
import { action, createStoreContext, CreateStoreOptions } from '@twocatmoon/react-actions';
import { ExtendedGameData } from '@/lib/drupal/models/Games';



// #region Schema & State

export interface RecentlyPlayedState {
  user: Record<string, ExtendedGameData[]>;
}

const initialState: RecentlyPlayedState = {
  user: {}
};

// #endregion



// #region Actions

export const recentlyPlayedActions = {
  addGame: action<RecentlyPlayedState, { game: ExtendedGameData, userId: string }>((prevState, payload) => {
    const userRecentlyPlayed = prevState.user[ payload.userId ] || [];

    if (userRecentlyPlayed.slice(0, 4).find(game => game.uid === payload.game.uid)) {
      return prevState;
    }
    userRecentlyPlayed.unshift(payload.game);

    return {
      ...prevState,
      user: {
        ...prevState.user,
        [ payload.userId ]: userRecentlyPlayed.slice(0, 127),
      }
    };
  }),
};

// #endregion



// #region Export

const options: CreateStoreOptions = {
  storageKey: getIdentifier('recentlyPlayed'),
  storageType: 'local',
  ssr: true,
};

export const { 
  Provider: RecentlyPlayedProvider, 
  useStore: useRecentlyPlayed, 
} = createStoreContext<RecentlyPlayedState>(initialState, recentlyPlayedActions, options);

// #endregion
