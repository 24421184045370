import { ReportEventResponseData } from '@/lib/util/logDNASendReportMiddlewares';
import axios, { AxiosResponse } from 'axios';
import { debugLog, config } from 'react-commons';

export async function logErrorEvent (
  label: string, 
  fatal: boolean = false,
  error: any,
): Promise<boolean> {
  debugLog(label, 'error', error);
  
  const reportOnLevels = config.REPORT_LOG_ON_LEVELS.split(',');

  if ((!fatal && reportOnLevels.includes('error')) || (fatal && reportOnLevels.includes('fatal'))) {
    const result = await axios.request<any, AxiosResponse<ReportEventResponseData>>({
      url: config.BASE_URL + '/api/report/error',
      method: 'post',
      data: {
        label,
        fatal,
        name: error.name,
        message: error.message,
        stack: error.stack,
        code: error.code,
        response: error.response,
      }
    });

    return result.data.ok;
  }

  return true;
}
